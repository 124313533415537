<template>
  <router-view />
</template>

<script setup lang="ts">
import { watch } from "vue";
import "./assets/css/main.css";
import { useDarkMode } from "./use-dark-mode";
useDarkMode();
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

* {
  box-sizing: border-box;
}
</style>