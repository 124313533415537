// src/composables/useDarkMode.ts
import { watch } from "vue";
import { useQuasar } from "quasar";

export function useDarkMode() {
    const $q = useQuasar();

    const setDarkModeColors = () => {
        document.documentElement.style.setProperty('--q-primary', '#616161'); // Light Purple
        document.documentElement.style.setProperty('--q-secondary', '#03dac6'); // Aqua
        document.documentElement.style.setProperty('--q-accent', '#cf6679'); // Pink
        document.documentElement.style.setProperty('--bg-white', '#212121'); // Dark bg-white
        document.documentElement.style.setProperty('--q-grey-2', '#424242');   // Dark grey-2
    };

    const resetLightModeColors = () => {
        document.documentElement.style.setProperty('--q-primary', '#2196f3'); // Blue
        document.documentElement.style.setProperty('--q-secondary', '#ffc107'); // Teal
        document.documentElement.style.setProperty('--q-accent', '#9C27B0'); // Purple
        document.documentElement.style.setProperty('--bg-white', '#ffffff'); // Light bg-white
        document.documentElement.style.setProperty('--q-grey-2', '#f5f5f5');   // Light grey-2
    };

    watch(
        () => $q.dark.isActive,
        (isDark) => {
            if (isDark) {
                document.body.classList.add('body--dark');
                setDarkModeColors();
            } else {
                document.body.classList.remove('body--dark');
                resetLightModeColors();
            }
        }
    );
}
